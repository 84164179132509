.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sortableHelper {
  z-index: 10000 !important;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.slick-dots .slick-dots-bottom {
  bottom: 0px !important;
}

.slick-active > button {
  background: #d3d3d3 !important;
}

.slick-dots > li {
  background-color: #000;
}

.slick-slide > div > div {
  display: flex !important;
  justify-content: center;
}

.slick-prev {
  margin-left: 1rem;
}

.slick-next {
  margin-right: 1rem;
}

.custom-antd-success-message
  > .ant-message-notice-content
  > .ant-message-success {
  display: flex;
  align-items: center;
}

.custom-marker-label {
  position: absolute !important;
  bottom: 80px !important;
  right: -45px !important;
  background-color: white !important;
  padding: 0.3rem 0.6rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  font-size: 12px !important;
}

.maplibregl-map {
  height: 100% !important;
  width: 100% !important;
}

.disable-swipe {
  overscroll-behavior-x: none;
}

.columm-resize-handler {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-switch-checked {
  background-color: #1659CB !important;
  border-color: #1659CB !important;
}

.ant-btn-primary {
  background-color: #1659CB !important;
  color: #FFFFFF !important;
}

.ant-col-rtl {
  text-align: right !important;
}

.ant-btn, .ant-select-selector, button, html [type="button"], [type="reset"], [type="submit"]: {
  border-radius: 0px !important;
}

.ant-tabs-nav-list, .ant-tabs-tab {
  transition: none !important;
  transform: none !important;
}
