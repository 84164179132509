body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

/* .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td {
  background: #eee !important;
} */
